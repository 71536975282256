import { Checkbox } from 'primereact/checkbox';
import React, { FC } from 'react';

import styles from '../styles.module.scss';
import {stepsKeys, TStep} from '../types';
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {useQuery} from "@tanstack/react-query";
import {TermsTypeKeys} from "../../../pages/TermsUpdate/utils";
import Loading from "../../Loading/Loading";
import EditorResult from "../../HtmlEditor/EditorResult";
import {PrivacyService} from "../../../pages/PolicyPages/PolicyService";

interface ITermsStepProps {
  setStep: React.Dispatch<React.SetStateAction<TStep>>;
  isTermsAccepted: boolean;
  setTermsState: (vl: boolean) => void;
  yourName?: string;
  signAndSubmit: () => void;
  isPublic?: boolean;
  paymentEnabled: boolean;
}

const TermsStep: FC<ITermsStepProps> = ({
setStep,
isTermsAccepted,
setTermsState,
signAndSubmit,
isPublic,
paymentEnabled,
}) => {
  const { data: latestPolicyContentData, isLoading } = useQuery({
    queryKey: ['terms-for-83b-d'],
    queryFn: () => PrivacyService.getContentByType(TermsTypeKeys.TERMS_FOR_83B),
    gcTime: 0,
    staleTime: 0,
  });
  const latestPolicyContent = latestPolicyContentData?.data;
  const policyContent = latestPolicyContent?.text;
  const handleSubmit = () => {
    if (!isTermsAccepted) return null;
    if (isPublic) {
      setStep(stepsKeys.finish);
    } else {
      signAndSubmit();
      if (paymentEnabled) {
        setStep(stepsKeys.pay);
      }
    }
  }
  return (
      <div className={styles.termsHolder}>
        <div className={styles.termsWidgetWrapper}>
          <div className={styles.termsWidget}>
            {/*<h3>Terms for 83(b) Election Service</h3>*/}
            <div className={styles.termRows}>
              {
                isLoading ?
                    (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Loading horizontalSpacing={120} />
                      </div>
                    ) : (
                      <EditorResult
                          content={policyContent}
                      />
                  )
              }
            </div>
            {
              !isLoading ? (
                  <div className={styles.checkboxHolder}>
                    <Checkbox
                        inputId="inputid"
                        name="terms"
                        onChange={() => setTermsState(!isTermsAccepted)}
                        checked={isTermsAccepted}
                    />
                    <label htmlFor="inputid">I have read and agree to these terms.</label>
                  </div>
              ) :null
            }

            <div className={styles.commonFooter}>
              <ButtonPrimary
                  label="Back to review"
                  styleType="secondaryOutline"
                  type="button"
                  onClick={() => setStep(stepsKeys.review)}
              />
              <ButtonPrimary
                  type="button"
                  onClick={handleSubmit}
                  label={isPublic ? "Next" : "Accept and Sign"}
                  disabled={!isTermsAccepted}
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default TermsStep;
