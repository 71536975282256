import React, {FC} from 'react';
import styles from './tooltip.module.scss';
import {classNames, classNames as cx} from "primereact/utils";
import {Tooltip} from "primereact/tooltip";

enum positions {
    top = 'top',
    left = 'left',
    bottom = 'bottom',
    right = 'right',
}
interface TTooltipProps {
    tooltipMessage: string;
    name?: string;
    label?: string;
    className?: string;
    position?: positions.top | positions.left | positions.bottom | positions.right;
}

const TooltipAndLabel: FC<TTooltipProps> = ({
name,
label,
className,
tooltipMessage = '',
position = positions.top,
}) => {
    return (
        <div className={classNames(styles.tooltip, className)}>
            <div className={styles.labelHolder}>
                {label}
            </div>
            <div className={styles.iconHolder}>
                <Tooltip target={`.${name}`} />
                <i
                    data-pr-tooltip={tooltipMessage}
                    data-pr-position={position}
                    className={cx(`pi pi-info-circle`)}
                ></i>
            </div>
        </div>
    )
}

export default TooltipAndLabel;
