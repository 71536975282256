import React, {useState, FC, useEffect, useLayoutEffect} from 'react';
import { useLocation } from 'react-router-dom';

import LogoSection from './LogoSection';
import NavigationSection from './NavigationSection';
import styles from './sidebar.module.scss';
import SidebarUiPlaceholder from './SidebarUiPlaceholder';
import UserSection from "./UserSection";
import {TUserCompanyConfigItem} from "../../@types/global";
import {HttpService} from "../../services/HttpService";

const toggledStyle = { width: '75px' };
const storageKey = 'sidebarOpened';

interface TSidebarProps {
  isAdmin: boolean;
  role: string;
  companyName: string;
  companies: TUserCompanyConfigItem[];
  selectedCompanyId: number;
  updateCompanyInfo: React.Dispatch<React.SetStateAction<TUserCompanyConfigItem>>;
  companyCode: string;
  subscriptionMenuVisible: boolean;
  isFile83bFeatureEnabled: boolean;
  isFillForm2553FeatureEnabled: boolean;
}

const Sidebar: FC<TSidebarProps> = ({
isAdmin,
role,
companyName,
companies,
selectedCompanyId,
updateCompanyInfo,
companyCode,
subscriptionMenuVisible,
isFile83bFeatureEnabled,
isFillForm2553FeatureEnabled,
}) => {

  const [isNeedSignature, setNeedSignature] = useState(false);
  const isOpenedFromStorage = Boolean(localStorage.getItem(storageKey));
  const [isClosed, toggle] = useState(!isOpenedFromStorage);
  const [isAnimate, setAnimate] = useState(false);
  const location = useLocation();

  const openCloseSidebar = () => {
    if (!isAnimate) {
      setAnimate(true);
    }
    if (isClosed) {
      localStorage.setItem(storageKey, storageKey);
      toggle(false);
    } else {
      localStorage.removeItem(storageKey);
      toggle(true);
    }
  };

  const setLayoutWithValue = () => {
    const rootEl = document.documentElement;
    const windowWidth = window.innerWidth;
    const sideBarClosedWidth = 75;
    const sidebarOpenedWidth = 264;
    const layoutWidth = windowWidth - (isClosed ? sideBarClosedWidth : sidebarOpenedWidth);
    rootEl.style.setProperty('--layout-width', `${layoutWidth}px`);
  }

  useEffect(() => {
    setLayoutWithValue();
  }, [isClosed]);

  useLayoutEffect(() => {
    setLayoutWithValue();
  }, [isClosed]);

  useEffect(() => {
    window.addEventListener('resize', setLayoutWithValue);
    return () => {
      window.removeEventListener('resize', setLayoutWithValue);
    };
  }, []);

  useEffect(() => {
    HttpService.get(
        `/api/companies/${selectedCompanyId}/form2553/config`
    ).then(res => {
      setNeedSignature(res.data.isNeedSignature);
    });
  }, [selectedCompanyId]);

  const sideBarLoading = false;
  if (location?.pathname === "/admin/form-2553" || location?.pathname === '/documents' || location?.pathname === '/acknowledgements/upload' || location?.pathname === '/acknowledgements') {
    return null;
  }
  return (
    <div className={styles.sidebarArea} style={isClosed ? toggledStyle : {}}>
      <div className={styles.sidebar} style={isClosed ? toggledStyle : {}}>
        {sideBarLoading ? (
          <SidebarUiPlaceholder />
        ) : (
          <>
            <div className={styles.sidebarBody}>
              <div className={styles.innerScroll}>
                <UserSection
                  isOpened={!isClosed}
                  roleLabel={(role || '').toLowerCase()}
                  userName={companyName}
                  companies={companies}
                  selectedCompanyId={selectedCompanyId}
                  updateCompanyInfo={updateCompanyInfo}
                  companyCode={companyCode}
                />
                <NavigationSection
                  isAdmin={isAdmin}
                  isOpened={!isClosed}
                  isAnimate={isAnimate}
                  companyId={selectedCompanyId}
                  isNeedSignature={isNeedSignature}
                  subscriptionMenuVisible={subscriptionMenuVisible}
                  isFile83bFeatureEnabled={isFile83bFeatureEnabled}
                  isFillForm2553FeatureEnabled={isFillForm2553FeatureEnabled}
                />
                <LogoSection
                    openCloseSidebar={openCloseSidebar}
                    isClosed={isClosed}
                    companyName={'Corpora'}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
